import { createStore } from 'vuex'
// import mailfurnaceApi from '@/services/api'
import { auth } from '@/store/auth.module'
// import jwtDecode from 'jwt-decode'
import mailfurnaceApi from '@/services/api'
import authHeader from '@/services/auth-header'
import VuexPersist from 'vuex-persist'

const vuexPersist = new VuexPersist({
  key: 'persistedState',
  storage: window.localStorage,
  reducer: (state) => ({
    auth: state.auth,
    user: state.user,
    messages_info: state.messages_info,
    all_messages: state.all_messages,
    email: state.email,
    mailbox_email: state.mailbox_email,
    current_mailbox_info: state.current_mailbox_info,
    contact_limit: state.contact_limit,
    contact_count: state.contact_count,
    account_level: state.account_level,
    daily_sends: state.daily_sends,
    service_provider: state.service_provider,
    messages_sent: state.messages_sent,
    landed_in_inbox: state.landed_in_inbox,
    landed_in_spam: state.landed_in_spam,
    opens: state.opens,
    replies: state.replies,
    clicks: state.clicks,
    mailboxes: state.mailboxes,
    is_active: state.is_active,
    emailPlacement: state.emailPlacement,
  }),
  asyncStorage: false, // use synchronous storage to avoid potential timing issues
  restoreState: (key, storage) => {
    const savedState = storage.getItem(key)
    console.log('Restoring state...')
    return JSON.parse(savedState || '{}')
  },
  saveState: (key, state, storage) => {
    console.log('Saving state...')
    storage.setItem(key, JSON.stringify(state))
  },
})

export default createStore({
  namespaced: true,
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    user: {},
    // isLoggedIn: false,
    email: null,
    mailbox_email: null,
    contact_limit: null,
    contact_count: null,
    account_level: null,
    service_provider: null,
    is_active: null,
    daily_sends: null,
    all_messages: {},
    messages_sent: null,
    landed_in_inbox: null,
    landed_in_spam: null,
    opens: null,
    replies: null,
    clicks: null,
    mailboxes: {},
    messages_info: {},
    current_mailbox_info: {},
  },
  getters: {
    // isLoggedIn: (state) => state.isLoggedIn,
    // user: (state) => state.user,
    isLoggedIn: (state) => state.auth.status.loggedIn,
    mailboxes: (state) => state.messages_info || [],
    getMailbox: (state) => state.current_mailbox_info.mailbox_email,
    getProvider: (state) => state.current_mailbox_info.service_provider,
    // getContactCount: (state) => state.contact_count,
    getContactLimit: (state) => state.current_mailbox_info.contact_limit,
    getDailySends: (state) => state.current_mailbox_info.daily_sends,
    getAccountLevel: (state) => state.current_mailbox_info.account_level,
    getMessagesInfo: (state) => state.current_mailbox_info,
    getMessagesSent: (state) => state.current_mailbox_info.messages_sent,
    getAllMessages: (state) => state.current_mailbox_info.all_messages,
    getAllMailboxes: (state) => state.mailboxes,
    getAllSpamMessages: (state) => state.current_mailbox_info.landed_in_spam,
    getEmailsOpened: (state) => state.current_mailbox_info.opens,
    getMailboxCompliance: (state) => state.current_mailbox_info.mailbox_compliance,
    getLandedInInbox: (state) => state.current_mailbox_info.landed_in_inbox,
    getAllReplyMessages: (state) => state.current_mailbox_info.replies,
    getAllClicks: (state) => state.current_mailbox_info.clicks,
    getLatestReceivedMessages: (state) => state.current_mailbox_info.latest_received_messages,
    getEmailPlacement: (state) => state.current_mailbox_info.emailPlacement,
    getRunningSentArray: (state, getters) => {
      let past_sent_data = getters.getAllMessages
      console.log('Past sent data: ', past_sent_data)
      if (!past_sent_data) {
        console.log('No past sent data found!')
        return []
      } else {
        let past_sent_data_values = []
        if (Array.isArray(past_sent_data)) {
          past_sent_data_values = past_sent_data.map((element) => {
            return Object.values(element)[0]
          })
        }

        past_sent_data_values = past_sent_data_values.filter((element) => {
          return element !== 0
        })

        // return it as values separated by commas
        // past_sent_data_values = past_sent_data_values.join(',')

        // past_sent_data_values = past_sent_data_values.slice(-7)

        // Flip the array so that the most recent data is at the beginning
        past_sent_data_values = past_sent_data_values.reverse()

        return past_sent_data_values
      }
    },
    getRunningOpensArray: (state, getters) => {
      let past_open_data = getters.getEmailsOpened
      // console.log('Past open data: ', past_open_data)
      if (!past_open_data) {
        console.log('No past open data found!')
        return []
      } else {
        let past_open_data_values = []
        if (Array.isArray(past_open_data)) {
          past_open_data_values = past_open_data.map((element) => {
            return Object.values(element)[0]
          })
        }

        past_open_data_values = past_open_data_values.filter((element) => {
          return element !== 0
        })

        // return it as values separated by commas
        // past_open_data_values = past_open_data_values.join(',')

        // past_open_data_values = past_open_data_values.slice(-7)

        // Flip the array so that the most recent data is at the beginning
        past_open_data_values = past_open_data_values.reverse()

        return past_open_data_values
      }
    },
    getRunningReplyArray: (state, getters) => {
      let past_reply_data = getters.getAllReplyMessages
      // console.log('Past reply data: ', past_reply_data)
      if (!past_reply_data) {
        console.log('No past reply data found!')
        return []
      } else {
        let past_reply_data_values = []
        if (Array.isArray(past_reply_data)) {
          past_reply_data_values = past_reply_data.map((element) => {
            return Object.values(element)[0]
          })
        }

        past_reply_data_values = past_reply_data_values.filter((element) => {
          return element !== 0
        })

        // return it as values separated by commas
        // past_reply_data_values = past_reply_data_values.join(',')

        // past_reply_data_values = past_reply_data_values.slice(-7)

        // Flip the array so that the most recent data is at the beginning
        past_reply_data_values = past_reply_data_values.reverse()

        return past_reply_data_values
      }
    },
    getClicksPercentage: (state, getters) => {
      let clicks = getters.getAllClicks
      console.log('Clicks: ', clicks)
    },
    getContactsRatio: (state, getters) => {
      let contact_ratio = getters.getMessagesInfo.contact_accounts_ratio
      // console.log('Contact ratio: ', contact_ratio)
      return contact_ratio
    },
    getEmailPlacementRatio(state, getters) {
      return getters.getMessagesInfo.email_placement_ratio
    },
    getAverageInboxing(state, getters) {
      return getters.getMessagesInfo.average_inboxing
    },
    getEmailsInboxed: (state, getters) => {
      console.log('Emails inboxed: ', getters.getMessagesInfo.emails_inboxed)
      return getters.getMessagesInfo.emails_inboxed
      // return 1000
    },
    getPreviouslyInboxed: (state, getters) => {
      return getters.getMessagesInfo.emails_inboxed
      // return 1000
    },
    getInboxPlacementTracking: (state, getters) => {
      return getters.getMessagesInfo.emailPlacementTracking
    },
    getProfile: (state, getters) => {
      return getters.getMessagesInfo.profile
    },
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    setUserVariables(state, payload) {
      console.log('Payload info: ', payload)
      state.email = payload.client_email
      // state.contact_limit = payload.mailboxes[0]['contact_limit']
      // state.mailboxes = payload.mailboxes
      // state.contact_count = payload.mailboxes[0]['contacts']
      // state.account_level = payload.mailboxes[0]['account_level']
      // state.service_provider = payload.mailboxes[0]['provider']
      state.is_active = payload.mailboxes[0]['active']
      // state.daily_sends = payload.mailboxes[0]['daily_sends']
      // Defaulting to the first mailbox when logging in
    },
    setInitialMailboxState(state, payload) {
      console.log('Setting initial mailbox state...', payload)

      if (payload) {
        state.mailboxes = payload
        state.current_mailbox_info.mailbox_email = Object.values(payload)[0].email_address
        state.current_mailbox_info.contact_limit = Object.values(payload)[0].contact_limit
        state.current_mailbox_info.account_level = Object.values(payload)[0].account_level
        state.current_mailbox_info.service_provider = Object.values(payload)[0].service_provider
        state.current_mailbox_info.is_active = Object.values(payload)[0].is_active
        state.current_mailbox_info.daily_sends = Object.values(payload)[0].daily_sends
        state.current_mailbox_info.messages_info = payload || []
        state.current_mailbox_info.all_messages = Object.values(payload)[0].messages
        state.current_mailbox_info.messages_sent = Object.values(payload)[0].messages_sent
        state.current_mailbox_info.landed_in_inbox = Object.values(payload)[0].landed_in_inbox
        state.current_mailbox_info.landed_in_spam = Object.values(payload)[0].landed_in_spam
        state.current_mailbox_info.opens = Object.values(payload)[0].opens
        state.current_mailbox_info.replies = Object.values(payload)[0].replies
        state.current_mailbox_info.mailbox_compliance = Object.values(payload)[0].mailbox_compliance
        state.current_mailbox_info.latest_received_messages =
          Object.values(payload)[0].latest_received_messages
        state.current_mailbox_info.contact_accounts_ratio = Object.values(payload)[0].contact_ratio
        state.current_mailbox_info.email_placement_ratio =
          Object.values(payload)[0].email_placement_ratio
        state.current_mailbox_info.average_inboxing = Object.values(payload)[0].average_inboxing
        state.current_mailbox_info.emails_inboxed = Object.values(payload)[0].emails_inboxed
        state.current_mailbox_info.emailPlacementTracking =
          Object.values(payload)[0].placement_tracking
        state.current_mailbox_info.profile = Object.values(payload)[0].profile
      } else {
        // Handle the case where payload is null or reset to default values
        console.error('Attempted to set initial mailbox with null payload')
        // Optionally reset to default state or do nothing
      }
    },
    setSelectedMailbox(state, payload) {
      if (payload) {
        console.log('Setting selected mailbox...', payload)
        // console.log('New Selected mailbox: ', selected_mailbox.email_address)

        // state.mailbox_email = payload.email_address
        // console.log('Setting payload selected mailbox email...', payload.email_address)
        state.current_mailbox_info.mailbox_email = payload.email_address
        // state.current_mailbox_info.email = payload.client_email
        state.current_mailbox_info.contact_limit = payload.contact_limit
        state.current_mailbox_info.account_level = payload.account_level
        state.current_mailbox_info.service_provider = payload.service_provider
        state.current_mailbox_info.is_active = payload.is_active
        state.current_mailbox_info.daily_sends = payload.daily_sends
        state.current_mailbox_info.all_messages = payload.messages
        state.current_mailbox_info.messages_info = payload || []
        state.current_mailbox_info.messages_sent = payload.messages_sent
        state.current_mailbox_info.landed_in_spam = payload.landed_in_spam
        state.current_mailbox_info.landed_in_inbox = payload.landed_in_inbox
        state.current_mailbox_info.opens = payload.opens
        state.current_mailbox_info.replies = payload.replies
        state.current_mailbox_info.mailbox_compliance = payload.mailbox_compliance
        state.current_mailbox_info.latest_received_messages = payload.latest_received_messages
        state.current_mailbox_info.contact_accounts_ratio = payload.contact_accounts_ratio
        state.current_mailbox_info.email_placement_ratio = payload.email_placement_ratio
        state.current_mailbox_info.average_inboxing = payload.average_inboxing
        state.current_mailbox_info.emails_inboxed = payload.emails_inboxed
        state.current_mailbox_info.emailPlacementTracking = payload.placement_tracking
        state.current_mailbox_info.profile = payload.profile
      } else {
        // Handle the case where payload is null or reset to default values
        console.error('Attempted to set mailbox with null payload')
        // Optionally reset to default state or do nothing
      }
    },
    setPlacementTrackingData(state, data) {
      state.current_mailbox_info.emailPlacementTracking = data
    },
    setLatestReceivedMessages(state, data) {
      state.current_mailbox_info.latest_received_messages = data
    },
  },
  actions: {
    // async registerUser({ dispatch }, user) {
    //   await mailfurnaceApi.post('/register', user)
    //   await dispatch('fetchUser')
    // },
    // async loginUser({ dispatch }, user) {
    //   await mailfurnaceApi.post('/login', user)
    //   await dispatch('fetchUser')
    // },
    // async fetchUser({ commit }) {
    //   await mailfurnaceApi
    //     .get('/user')
    //     .then(({ data }) => commit('setUser', data))
    // },
    // async logoutUser({ commit }) {
    //   await mailfurnaceApi.post('/logout')
    //   commit('logoutUserState')
    // },
    async setUserInfo({ commit }, user) {
      console.log('Setting user information...')
      commit('setUserVariables', user)
    },
    async setMessagesInfo({ commit }) {
      console.log('Retrieving messages data...')
      try {
        return mailfurnaceApi.get('info/messages', { headers: authHeader() }).then((response) => {
          if (response.data) {
            console.log('Retrieved message info!')

            // Clear the current Vuex state that contains the messages info
            // commit('setTotalEmails', {})

            // Set the Vuex state to the new messages info
            commit('setInitialMailboxState', response.data)
            console.log('Messages info: ', response.data)
          } else {
            // localStorage.removeItem('token')
            // console.log('No token provided (auth.service.js, login)')
            console.log('ERROR: No messages info found!')
          }
          // commit('getTotalEmails', response.data)
        })
      } catch (error) {
        console.log('Error: ', error)
      }
    },
    async setSelectedMailbox({ commit }, mailbox) {
      console.log('(async action) Setting selected mailbox...', mailbox)
      commit('setSelectedMailbox', mailbox)
    },
  },
  modules: {
    auth,
  },
  plugins: [vuexPersist.plugin],
})
